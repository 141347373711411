<template>
  <div class="ChangePwd">
    <div v-if="formData.name">
      <div class="title">{{ formData.name }}</div>
      <div class="title"><el-button type="primary" style="margin-left: 20px;" @click="down">确认下载</el-button></div>
      <div class="remarks">每人仅限下载一次</div>
    </div>
    <div v-else>
      <div class="title"> 暂无数据，更新中 </div>
    </div>

  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      formData: {
        name: ''
      }
    };
  },
  computed: {
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$fecth.post("down_zhuanke/getDataById").then((res) => {
        this.formData = res.data
        console.log(res)
      });
    },
    down() {
      let UserInfo = JSON.parse(localStorage.getItem('UserInfo'))

      this.$fecth.post("down_zhuanke/down").then((res) => {
        let { code, msg } = res
        let url
        if (code == 200) {
          if (UserInfo.is_wenli == 1) {
            url = this.formData.url;
          } else {
            url = this.formData.url_1;
          }
          window.open(url, '_blank');
        } else {
          this.$message(msg);
        }
      });
    }
  },
};
</script>

<style scoped lang='less'>
.ChangePwd {
  background-color: #ffffff;

  .title {
    font-size: 26px;
    height: 50px;
    line-height: 57px;
    padding: 0 30px;
    text-align: center;
  }

  min-height: 400px;
  padding-top: 140px;

  .remarks {
    text-align: center;
    color: #dfdfdf;
    margin-top: 40px;
  }
}
</style>
